export const PublishTypes = {
  /// <summary>非公開</summary>
  Private: 1,
  /// <summary>公開</summary>
  Public: 1 << 1,
};

export const WaterMarkTypes = {
  None: 1,
  // スクショにウォーターマークをつける
  ScreenShot: 1 << 1,
};

export const ShotRoles = {
  // 設定なし
  None: 1,

  // 公開設定
  Publish: 1 << 1,

  // 作者設定
  Author: 1 << 2,

  // tag設定
  Tag: 1 << 3,

  // description設定
  Description: 1 << 4,

  // rate(Recommend)
  Rate: 1 << 5,

  // ウォーターマーク設定
  WaterMark: 1 << 6,

  // スクショの直リンク、ダウンロード
  ScreenShotDL: 1 << 10,
};

export const ShotTypes = {
  Url: 1,
  //
  Image: 1 << 1,
  //
  Text: 1 << 2,
};
