import md5 from "js-md5";

export function uploaderAsync({ accept = "image/jpeg", multiple = false }) {
  return new Promise((resolve, reject) => {
    try {
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", accept);
      if (multiple) input.setAttribute("multiple", multiple);
      input.onchange = () => {
        resolve(input.files);
      };
      input.click();
    } catch (ex) {
      reject(ex);
    }
  });
}

export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });
}

export function resizeAsync(src, targetWidth, contentType = "image/png") {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.src = src;

      image.onload = () => {
        // 読み込んだsrcからサイズ取得
        let width = image.width;
        let height = image.height;

        // 横幅でスケール取得
        let scale = targetWidth / width;
        // 横幅優先、ただし拡大されてしまう場合原寸
        if (scale > 1) scale = 1;
        // スケールをもとにリサイズサイズ指定
        const canvas = document.createElement("canvas");
        canvas.width = width * scale;
        canvas.height = height * scale;
        canvas
          .getContext("2d")
          .drawImage(image, 0, 0, canvas.width, canvas.height);
        let resizedSrc = canvas.toDataURL(contentType);

        resolve(resizedSrc);
      };
    } catch (error) {
      reject(error);
    }
  });
}

export function getMd5(file) {
  const hash = md5.create();
  const chunkSize = 1000; // 一度に読み取るサイズを指定
  let offset = 0; // 読込開始位置
  let blockRead = null;

  return new Promise((resolve, reject) => {
    // 分割されたファイルの読み込み関数
    const readContent = (evt) => {
      const result = new Uint8Array(evt.target.result);

      if (evt.target.error) {
        // エラー処理を記述・・・
      }

      offset += result.length;
      hash.update(result);

      if (offset >= file.size) {
        // 計算結果表示
        resolve(hash.hex());
      } else {
        blockRead(offset);
      }
    };
    // 再帰読込関数
    blockRead = (_offset) => {
      const blob = file.slice(_offset, chunkSize + _offset);
      const reader = new FileReader();
      reader.onloadend = readContent;
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsArrayBuffer(blob);
    };

    // ファイル読み込み開始
    blockRead(offset);
  });
}
